<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>

<app-loader v-if="isLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />

  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div
        class="d-flex flex-wrap justify-content-between align-items-center py-9 px-20 px-15-sm border-bottom-normal-1"
      >
        <div class="w-100-vsm">
          <h2 class="font-16 font-w-500 mb-0">Flow Automation</h2>
          <p class="font-12 color-light2 m-0">
            Create a series of automated actions using triggers, processes, and
            more.
          </p>
        </div>
        <div class="w-100-vsm">
          <kbutton
            v-if="!ifUserIsReportViewer"
            :class="'d-flex align-items-center'"
            :theme-color="'primary'"
            :disabled="!hasFlowCampaignPostPermission()"
            @click="navigateToCreatePage"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 7.5V3.5H7V7.5H3V9.5H7V13.5H9V9.5H13V7.5H9Z"
                fill="white"
              />
            </svg>
            Create Flow Automation
          </kbutton>
        </div>
      </div>

      <div
        v-if="campaignsList?.items?.length > 0 || searchText || campaignValue?.id !=0 || campaignTypeValue?.id !=0 ||filterByDateType?.id!=0 "
        class="d-flex flex-wrap justify-content-between px-20 px-15-sm pt-20 pb-0"
      >
        <div class="d-flex flex-wrap align-items-start gap-3">
          <div class="tab-with-button-wrap d-flex mb-9">
            <kbutton
              :class="{ active: !isArchived }"
              @click="toggleCampaign(false)"
              >All Flow</kbutton
            >
            <kbutton
              :class="{ active: isArchived }"
              @click="toggleCampaign(true)"
              >Archived Flow</kbutton
            >
          </div>
          <div
            class="search-box-wrap width-220 w-100-vsm mb-9 me-0 me-sm-3 me-md-4"
          >
            <span class="k-icon k-i-zoom"></span>
            <k-input
              :class="'mb-0'"
              placeholder="Search"
              v-model="searchQuery"
              @input="debounceSearch"
            >
            </k-input>
          </div>
        </div>

        <div class="d-flex flex-wrap gap-4">
          <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Status</label
              >
              <dropdownlist
                :class="'w-100-sm'"
                :style="{ width: '160px' }"
                :data-items="flowStatusTemplates"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="flowStatusValue"
                @change="handleCampaignStatusChange"
              >
              </dropdownlist>
            </span>
          </div>
          <!-- <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="pe-2 w-100-vsm">Filter by Campaign Type:</label>
              <dropdownlist :class="'w-100-vsm'" :style="{ width: '190px' }" :data-items="campaignType"
                :text-field="'text'" :data-item-key="'id'" :value="campaignTypeValue"
                @change="handleCampaignTypeStatusChange">
              </dropdownlist>
            </span>
          </div> -->
          <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Date</label
              >
              <dropdownlist
                :class="'w-100-vsm'"
                :style="{ width: '140px' }"
                :data-items="filterByDateType"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="filterByDateValue"
                @change="handleValueChange($event.value, 'filterByDateValue')"
              >
              </dropdownlist>
            </span>
          </div>
          <div class="w-100-vsm"
              v-if="filterByDateValue?.id == 4">
            <AppDateRangePicker
              @dateSelected="handleDateSelected"
              @error="handleError"
              :class="'w-100-vsm'"
              :style="{ width: '210px' }"
            >
            </AppDateRangePicker>
          </div>
        </div>
      </div>
    </div>

    <!--dashboard-content-->
    <div class="dashboard-content py-0 px-20 px-15-sm">
      <grid
        v-if="flowsList?.length > 0"
        :class="'flow-automation-table table-design-2 mb-60 2xl:h-[48.813rem] lg:h-[37.75rem]'"
        :data-items="flowsList"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        @datastatechange="dataStateChange"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
        :columns="gridColumns"
      >
        <template v-slot:nameCell="{ props }">
          <td>
            <router-link
              :to="{ name: 'viewFlowDetails', 
              params: {...this.$route.params,flowAutomationName: props.dataItem?.autoflowName,flowId: props.dataItem?.id},
              query: {flowAutomationName: props.dataItem?.autoflowName}}"
            >
              <span class="font-14 font-w-500 color-blue"
                >{{props.dataItem.autoflowName}}</span
              >
            </router-link>

            <div
              class="campaign-transaction d-flex flex-wrap justify-content-start align-items-center"
            >
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot purple radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Sent: {{props.dataItem?.totalSent}}
                </div>
              </div>
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot green radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Delivered: {{props.dataItem?.totalDelivered}}
                </div>
              </div>
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot red radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Bounced: {{props.dataItem?.totalBounced}}
                </div>
              </div>
            </div>
          </td>
        </template>

        <template v-slot:statusCell="{ props }">
          <td>
            <span
              :class="['campaign-status styled-2 font-12 font-w-600 radius-100 py-1 px-8',flowStatusColor[props?.dataItem?.autoFlowStatus]]"
            >
              {{autoFlowStatusList[props?.dataItem?.autoFlowStatus]}}
            </span>
          </td>
        </template>

        <template v-slot:createdAtCell="{ props }">
          <td>
            <p class="font-14 text-color m-0">
              {{ getDate(props.dataItem?.createdAt)}}
            </p>
            <p class="font-12 text-color-2 m-0">
              {{ getTime(props.dataItem?.createdAt)}}
            </p>
          </td>
        </template>
        <template v-slot:LastUpdateAtCell="{ props }">
          <td>
            <p class="font-14 text-color m-0">
              {{ getDate(props.dataItem?.updatedAt)}}
            </p>
            <p class="font-12 text-color-2 m-0">
              {{ getTime(props.dataItem?.updatedAt)}}
            </p>
          </td>
        </template>

        <template v-slot:openRateCell="{ props }">
          <td>
            <p
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.uniqueOpened,props.dataItem?.uniqueSent) ? '' : 'null']"
            >
              {{props.dataItem?.overallOpenRate}}%
            </p>
            <p class="font-12 m-0">
              {{props.dataItem?.totalDelivered||0}} Recipients
            </p>
          </td>
        </template>
        <template v-slot:bounceRateCell="{ props }">
          <td>
            <p
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.totalBounced,props.dataItem?.totalSent) ? '' : 'null']"
            >
              {{props.dataItem?.overallBounceRate}}%
            </p>
            <p class="font-12 m-0">
              {{props.dataItem?.uniqueSent||0}} Recipients
            </p>
          </td>
        </template>
        <template v-slot:conversionRateCell="{ props }">
          <td>
            <h3
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.totalConversion,props.dataItem?.totalDelivered) ? '' : 'null']"
            >
              {{props.dataItem?.overallConversionRate}}%
            </h3>
            <p class="font-12 m-0">
              ${{props.dataItem?.totalConversionValue||0}} Conv. value
            </p>
          </td>
        </template>
        <template v-slot:actionCell="{ props }">
          <td>
            <AppPopUp
              :voucher="card"
              :items="generateFlowActions(props.dataItem?.autoFlowStatus,props.dataItem )"
              @dropId="getDropId($event, props.dataItem)"
              :icon="'more-vertical'"
              :popUpSettings="popupSettings"
              :buttonClass="'btn-clear'"
            >
            </AppPopUp>
          </td>
        </template>
      </grid>

      <div
        v-if="!isLoading && flowsList?.length === 0 "
        class="d-flex flex-column justify-content-center align-items-center mt-5 pt-lg-5 pt-sm-2 pt-0"
      >
        <component is="flowAutomationEmptyState"></component>
        <h5
          class="font-19 text-color mt-32 mb-1"
        >
          Flow Automation
        </h5>
        <!-- <h5
          v-if="!searchQuery && !(flowStatusValue?.id !=0 && flowsList.length == 0) "
          class="font-19 text-color mt-32 mb-1"
        >
          Flow Automation
        </h5> -->
        <p
          v-if="!searchQuery && !(campaignValue?.id !=0 && campaignsList?.items?.length == 0) && isArchived"
          class="font-14 color-light2 mt-4 mb-4 text-center"
        >
          Archive is currently empty. When you archive a flow, <br> it will appear
          here.
        </p>
        <p
          v-if="!searchQuery && !(flowStatusValue?.id !=0 && flowsList.length == 0) && !isArchived "
          class="font-14 color-light2 mb-4 text-center"
        >
          Automate your emails by defining flows and <br />
          monitoring activities with ease.
        </p>
        <p
          v-if="searchQuery || (flowStatusValue?.id !=0 && flowsList.length == 0) && !isArchived "
          class="font-14 color-light2 mb-4 text-center"
        >
          No Flow found matching your search/filter criteria.
        </p>
        <kbutton
          v-if="!searchQuery && !(flowStatusValue?.id !=0 && flowsList.length == 0) && !isArchived"
          :disabled="!hasFlowCampaignPostPermission()"
          :class="'mb-3'"
          :theme-color="'primary'"
          @click="navigateToCreatePage"
        >
          Create Flow Automation
        </kbutton>
      </div>
    </div>
  </div>

  <template v-for="key in Object.keys(this.warningModals)" :key="key">
    <WarningModal
      v-if="warningModals[key]?.visibility"
      :modalDetails="warningModals[key]?.modalDetails"
      :onCancel="() => onCancelWarningModal(key)"
      :onConfirm="() => warningModals[key].onConfirm(warningModals[key]?.payload)"
    />
  </template>

  <WarningModal
    v-if="deleteFlowModalVisibility"
    :modalDetails="deleteFlowModalDetails"
    :onCancel="onCancelDeleteFlowModal"
    :onConfirm="onConfirmDeleteFlowModal"
  />

  <WarningModal
    v-if="pauseFlowWarningModalVisibility"
    :modalDetails="pauseFlowWarningModalDetails"
    :onCancel="onCancelPauseFlow"
    :onConfirm="onConfirmPauseFlow"
  />

  <WarningModal
    v-if="resumeFlowWarningModalVisibility"
    :modalDetails="resumeFlowWarningModalDetails"
    :onCancel="onCancelResumeFlow"
    :onConfirm="onConfirmResumeFlow"
  />

  <!-- <RescheduleFlowModal :prePayload="selectedFlow" :onCancel="onCancelRescheduleFlow"
    :onConfirm="onConfirmRescheduleFlow" v-if="RescheduleFlowModalVisibility" /> -->
</div>
