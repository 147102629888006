<template>
    <svg width="217" height="184" viewBox="0 0 217 184" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.05" cx="109" cy="92" r="92" fill="#253858"/>
    <g filter="url(#filter0_bdd_6211_71076)">
    <rect x="31.9402" y="54.8408" width="154.563" height="98.2819" rx="1.93432" fill="#DEDEDE" fill-opacity="0.13" shape-rendering="crispEdges"/>
    </g>
    <g filter="url(#filter1_bdd_6211_71076)">
    <rect x="28.2571" y="45.7461" width="161.928" height="102.965" rx="1.88497" fill="#DEDEDE" fill-opacity="0.13" shape-rendering="crispEdges"/>
    </g>
    <g filter="url(#filter2_dd_6211_71076)">
    <rect x="23" y="35" width="170.842" height="108.97" rx="3.69388" fill="white"/>
    </g>
    <line x1="148.5" y1="93" x2="148.5" y2="82" stroke="#D1E1F9"/>
    <line x1="60" y1="92.5" x2="76" y2="92.5" stroke="#D1E1F9"/>
    <rect x="60" y="53" width="33" height="13" rx="6.5" fill="#D1E1F9"/>
    <rect x="52" y="119" width="65" height="13" fill="#88B0ED"/>
    <rect x="133" y="119" width="33" height="13" rx="6.5" fill="#D1E1F9"/>
    <rect x="133" y="53" width="33" height="26" fill="#88B0ED"/>
    <path d="M130 59L125 56.1133L125 61.8868L130 59ZM96 59.5L125.5 59.5L125.5 58.5L96 58.5L96 59.5Z" fill="#D1E1F9"/>
    <path d="M130 126L125 123.113V128.887L130 126ZM120 126.5H125.5V125.5H120V126.5Z" fill="#D1E1F9"/>
    <path d="M119 93L124 95.8868V90.1132L119 93ZM149 92.5H123.5V93.5H149V92.5Z" fill="#D1E1F9"/>
    <path d="M60 116L62.8868 111L57.1132 111L60 116ZM59.5 92L59.5 111.5L60.5 111.5L60.5 92L59.5 92Z" fill="#D1E1F9"/>
    <circle cx="97.5" cy="92.5" r="18.5" fill="#A4C3F3"/>
    <defs>
    <filter id="filter0_bdd_6211_71076" x="23.901" y="46.8017" width="170.641" height="114.36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.01957"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6211_71076"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.81524"/>
    <feGaussianBlur stdDeviation="1.81524"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_6211_71076" result="effect2_dropShadow_6211_71076"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4.01957"/>
    <feGaussianBlur stdDeviation="2.00978"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
    <feBlend mode="normal" in2="effect2_dropShadow_6211_71076" result="effect3_dropShadow_6211_71076"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6211_71076" result="shape"/>
    </filter>
    <filter id="filter1_bdd_6211_71076" x="20.4622" y="37.9512" width="177.518" height="118.555" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.89744"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6211_71076"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.76009"/>
    <feGaussianBlur stdDeviation="1.76009"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_6211_71076" result="effect2_dropShadow_6211_71076"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3.89744"/>
    <feGaussianBlur stdDeviation="1.94872"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
    <feBlend mode="normal" in2="effect2_dropShadow_6211_71076" result="effect3_dropShadow_6211_71076"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6211_71076" result="shape"/>
    </filter>
    <filter id="filter2_dd_6211_71076" x="0.8367" y="20.2245" width="215.169" height="153.296" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7.38777"/>
    <feGaussianBlur stdDeviation="11.0816"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6211_71076"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.84694"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_6211_71076" result="effect2_dropShadow_6211_71076"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6211_71076" result="shape"/>
    </filter>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "flowAutomationEmptyState"
    }
</script>
<style scoped></style>