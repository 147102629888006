<template src="./flowAutomation.html"></template>
<style lang="scss">
@import "./flowAutomation.scss";
</style>

<script>
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
} from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar";
import AppLoader from "@/components/common/AppLoader/AppLoader";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import flowAutomationEmptyState from "@/components/svg-icons/flowAutomationEmptyState.vue";
import {
  getFlowAutomationListService,
  cloneFlowAutomationService,
  deleteFlowAutomationService,
  updateFlowAutomationStatusService,
  archiveAutoFlowService,
} from "@/services/clubDetails/flowAutomation.service";

import WarningModal from "@/components/clubDetails/emailCampaign/warningModal/WarningModal.vue";
import RescheduleCampaignModal from "@/components/clubDetails/emailCampaign/rescheduleCampaignModal/RescheduleCampaignModal.vue";
import moment from "moment";
import { Grid } from "@progress/kendo-vue-grid";
import AppDateRangePicker from "@/components/common/AppDateRangePicker/AppDateRangePicker.vue";
export default {
  name: "flowAutomation",
  components: {
    kbutton: Button,
    "k-input": Input,
    dropdownlist: DropDownList,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    Popup: Popup,
    AppPopUp,
    Breadcrumb,
    AppSnackBar,
    AppLoader,
    WarningModal,
    RescheduleCampaignModal,
    flowAutomationEmptyState,
    AppDateRangePicker,
    Grid,
  },
  data() {
    return {
      isArchived: false,
      sort: [],
      sortBy: "",
      sortDir: "",
      filterByDateType: [
        { text: "All Time", id: 0 },
        { text: "7 days", id: 1 },
        { text: "30 days", id: 2 },
        { text: "90 days", id: 3 },
        { text: "Custom", id: 4 },
      ],
      filterByDateValue: { text: "All Time", id: 0 },
      startDate: null,
      endDate: null,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100],
        previousNext: true,
      },

      selectedFlowAutomation: "",
      isLoading: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      debounce: null,
      clubId: this.$route.params.id,
      searchQuery: "",
      searchText: "",
      cardIdDlt: "",
      isSearching: false,
      flowStatusTemplates: [
        { text: "All", id: 0 },
        { text: "Live", id: 2 },
        { text: "Draft", id: 1 },
        // { text: "Scheduled", id: 3 },
        { text: "Paused", id: 4 },
        // { text: "Completed", id: 5 },
        // { text: "Cancelled", id: 6 },
      ],
      flowStatusValue: { text: "All", id: 0 },
      show: true,

      flowsList: [],
      flowData: {},
      autoFlowStatusList: {
        1: "Draft",
        2: "Live",
        3: "Scheduled",
        4: "Paused",
        5: "Completed",
        6: "Cancelled",
      },

      flowStatusColor: {
        1: "draft",
        2: "live",
        3: "scheduled",
        4: "paused",
        5: "live",
        6: "",
      },

      warningModalVisibility: false,
      warningModalDetails: {
        title: "",
        message: "",
        confirmButton: "",
        cancelButton: "",
      },

      warningModals: {
        archiveAutoflowModal: {
          visibility: false,
          payload: {},
          modalDetails: {
            title: "Archive Automation",
            message:
              "You are about to archive this flow. Are you sure you want to archive the flow automation?",
            confirmButton: "Archive",
            cancelButton: "Cancel",
          },
          onConfirm: this.onArchiveDiscardFlowHandler,
        },
        unarchiveAutoflowModal: {
          visibility: false,
          payload: {},
          modalDetails: {
            title: "Unrchive Automation",
            message:
              "You are about to unarchive this flow. Are you sure you want to discard the flow automation?",
            confirmButton: "Unarchive Flow",
            cancelButton: "Cancel",
          },
          onConfirm: this.onArchiveDiscardFlowHandler,
        },
      },

      payloadForDeleteFlowAutomation: {},
      deleteFlowModalVisibility: false,
      deleteFlowModalDetails: {
        title: "Delete Automation",
        message:
          "You are about to delete automation that was saved as a draft. You will lose all saved settings and information. Are you sure you want to delete the automation?",
        confirmButton: "Delete Flow",
        cancelButton: "Cancel",
      },

      resumeFlowWarningModalVisibility: false,
      payloadForResumeFlowAutomation: {},
      resumeFlowWarningModalDetails: {
        title: "Resume Automation",
        message:
          "You are about to resume automation that was paused. Are you sure you want to resume the automation?",
        confirmButton: "Resume Flow",
        cancelButton: "Cancel",
      },

      pauseFlowWarningModalVisibility: false,
      payloadForPauseFlowAutomation: {},
      pauseFlowWarningModalDetails: {
        title: "Pause Automation",
        message:
          "All automation activities will be paused, and no recipients will enter the flow automation if paused. And no further analytics will be generated. Are you sure you want to pause the automation?",
        confirmButton: "Pause Flow",
        cancelButton: "Cancel",
      },

      RescheduleCampaignModalVisibility: false,
      campaignDetailsForReschedule: {},
    };
  },
  created() {
    const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
    if (clubInfo?.roleId === 2) {
      this.ifUserIsReportViewer = true;
    } else {
      this.ifUserIsReportViewer = false;
    }
  },
  mounted() {
    if (!this.hasFlowCampaignGetPermission()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to flow automation.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.isNotify = false;
    this.getFlowsList({
      ...this.generatePayload(),
      limit: 10,
      offset: 0,
    });
  },
  computed: {
    clubName() {
      return this.$store.state.club.club.name;
    },
    total() {
      return this.flowData ? this.flowData?.size : 0;
    },
    take() {
      return this.flowData ? this.flowData?.limit : 10;
    },
    skip() {
      return this.flowData ? this.flowData?.offset : 0;
    },
    gridColumns() {
      return [
        {
          field: "campaignName",
          cell: "nameCell",
          title: "Flow Name",
          sortable: false,
        },
        {
          field: "status",
          cell: "statusCell",
          title: "Status",
          sortable: false,
        },
        {
          field: "createdAt",
          cell: "createdAtCell",
          title: "Created On",
          sortable: true,
        },
        {
          field: "LastUpdateAt",
          cell: "LastUpdateAtCell",
          title: "Last Updated On",
          sortable: true,
        },
        {
          field: "openRate",
          cell: "openRateCell",
          title: "Open Rate",
          sortable: false,
        },
        {
          field: "bounceRate",
          cell: "bounceRateCell",
          title: "Bounce Rate",
          sortable: false,
        },
        {
          field: "conversionRate",
          cell: "conversionRateCell",
          title: "Conversion",
          sortable: false,
        },
        {
          field: "",
          cell: "actionCell",
          title: "Action",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    onCancelWarningModal(key) {
      this.warningModals[key].visibility = false;
    },
    onOpenWarningModal(key, payload = {}) {
      this.warningModals[key].visibility = true;
      this.warningModals[key].payload = payload;
    },
    onArchiveDiscardFlowHandler(payload) {
      this.isLoading = true;
      this.onCancelWarningModal("archiveAutoflowModal");
      this.onCancelWarningModal("unarchiveAutoflowModal");
      archiveAutoFlowService(payload)
        .then((res) => {
          this.isLoading = false;

          if (payload.isArchived) {
            this.showNotification(
              "Flow automation archived successfully.",
              "success"
            );
          } else {
            this.showNotification(
              "Flow automation unarchived successfully.",
              "success"
            );
          }

          this.getFlowsList({
            ...this.generatePayload(),
            limit: this.take,
            offset: this.skip,
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        ...this.generatePayload(),
        limit: event.data.take,
        offset: event.data.skip,
      };

      this.getFlowsList(payload);
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;

      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      let payload = {
        ...this.generatePayload(),
        limit: this.take,
        offset: this.skip,
      };

      this.getFlowsList(payload);
    },
    toggleCampaign(isArchived) {
      this.isArchived = isArchived;

      this.searchQuery = "";
      this.flowStatusValue = { text: "All", id: 0 };
      this.startDate = null;
      this.endDate = null;
      this.filterByDateValue = { text: "All Time", id: 0 };

      let payload = this.generatePayload();
      this.getFlowsList(payload);
    },
    generatePayload() {
      return {
        clubId: this.clubId,
        isArchived: this.isArchived,
        ...(this.flowStatusValue?.id && {
          autoFlowStatus: this.flowStatusValue?.id,
        }),
        ...(this.searchQuery && {
          searchQuery: this.searchQuery,
        }),
        ...(this.filterByDateValue?.id == 4 && {
          startDate: moment(this.startDate).format("DD-MMM-YYYY"),
          endDate: moment(this.endDate).format("DD-MMM-YYYY"),
          filterByDateType: this.filterByDateValue?.id,
        }),
        ...(this.filterByDateValue?.id != 4 && {
          filterByDateType: this.filterByDateValue?.id,
        }),
        ...(this.sortBy &&
          this.sortDir && {
            sort: this.sortBy,
            order: this.sortDir,
          }),
      };
    },
    handleDateSelected({ start, end }) {
      if (start == null && end == null) {
        this.filterByDateValue = { text: "All Time", id: 0 };
      }

      this.startDate = start;
      this.endDate = end;

      this.getFlowsList({
        ...this.generatePayload(),
        limit: this.take - this.skip,
        offset: 0,
      });
    },
    handleValueChange(value, state) {
      this[state] = value;

      if (this.filterByDateValue.id != 4) {
        this.getFlowsList({
          ...this.generatePayload(),
          limit: this.take - this.skip,
          offset: 0,
        });
      }
    },
    generateFlowActions(id, propsItem) {
      let actions = {
        1: [
          ...(!this.hasFlowCampaignDeletePermission()
            ? []
            : [
                {
                  text: "Delete",
                  dropId: 3,
                },
              ]),
          ...(!this.hasFlowCampaignPostPermission()
            ? []
            : [
                {
                  text: "Clone",
                  dropId: 1,
                },
              ]),
          ...(!this.hasFlowCampaignPutPermission()
            ? []
            : [
                {
                  text: "Edit",
                  dropId: 6,
                },
              ]),
        ],
        2: [
          ...(!this.hasFlowCampaignPutPermission()
            ? []
            : [
                {
                  text: "Pause",
                  dropId: 4,
                },
              ]),
          ...(!this.hasFlowCampaignPostPermission()
            ? []
            : [
                {
                  text: "Clone",
                  dropId: 1,
                },
              ]),
        ],
        4: [
          ...(!this.hasFlowCampaignPutPermission()
            ? []
            : [
                {
                  text: "Resume",
                  dropId: 5,
                },
                {
                  text: "Edit",
                  dropId: 6,
                },
              ]),
          ...(!this.hasFlowCampaignPostPermission()
            ? []
            : [
                {
                  text: "Clone",
                  dropId: 1,
                },
              ]),
        ],
      };

      if (this.hasFlowCampaignPutPermission()) {
        if (propsItem.isArchived) {
          actions[id].push({
            text: "Unarchive",
            dropId: 8,
          });
        } else {
          actions[id].push({
            text: "Archive",
            dropId: 7,
          });
        }
      }

      return actions[id];
    },
    hasFlowCampaignPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignPutPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignGetPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignDeletePermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    emailOptionsGenerator(total, option) {
      if (total || option) {
        return (100 / total) * option;
      } else {
        return 0;
      }
    },
    getFlowsList(payload) {
      this.isLoading = true;
      getFlowAutomationListService(payload)
        .then((res) => {
          this.flowData = res?.data?.data;
          this.flowsList = res?.data?.data?.items;
          this.isLoading = false;

          if (payload.searchQuery) {
            this.searchText = payload.searchQuery;
          }
        })
        .catch((err) => {
          // ;
          this.isLoading = false;
        });
    },

    getDate(date) {
      if (date != null) {
        if (!date.includes("Z")) {
          date += "Z";
        }
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },

    getTime(date) {
      if (date != null) {
        if (!date.includes("Z")) {
          date += "Z";
        }
        return moment(date).format("hh:mmA");
      }
    },

    doNavigateToFlowDetails(flow) {
      this.$router.push({
        name: "viewFlowDetails",
        params: {
          ...this.$route.params,
          flowAutomationName: flow?.autoflowName,
          flowId: flow?.id,
        },
        query: {
          flowAutomationName: flow?.autoflowName,
        },
      });
    },

    doNavigateToEditFlow(flow) {
      this.$router.push({
        name: "workflowEditorUpdate",
        params: {
          ...this.$route.params,
          flowId: flow?.id,
        },
        query: {
          type: 2,
        },
      });
    },

    handleCampaignStatusChange(e) {
      this.flowStatusValue = e.value;
      this.getFlowsList({
        ...this.generatePayload(),
        limit: this.take - this.skip,
        offset: 0,
      });
    },
    navigateToCreatePage() {
      this.$router.push({
        name: "createFlowAutomation",
      });
    },

    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },

    onSearch(e) {
      this.searchQuery = e.value;
      this.isSearching = true;

      if (!this.searchQuery) {
        setTimeout(() => {
          this.isSearching = false;
        }, 1000);
      }

      let payload = {
        ...this.generatePayload(),
        offset: 0,
        limit: this.take - this.skip,
      };

      this.getFlowsList(payload);
    },

    navigateToViewAnalytics(flow) {
      if (flow?.autoFlowStatus == 1) {
        this.$router.push({
          name: "CampaignAnalytics",
          params: {
            ...this.$route.params,
            campaignId: flow?.id,
            campaignName: flow?.autoflowName,
          },
          query: {
            type: 2,
            campaignName: flow?.autoflowName,
          },
        });
      } else {
        this.$router.push({
          name: "CampaignAnalytics",
          params: {
            campaignId: flow?.id,
          },
          query: {
            type: 2,
            campaignName: flow?.autoflowName,
          },
        });
      }
    },
    cardNameFormat(name) {
      return name.length <= 50 ? name : name.substring(0, 50) + "..";
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    cloneFlowAutomationHandler(payload) {
      this.isLoading = true;
      cloneFlowAutomationService(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification(
            "Flow automation cloned successfully.",
            "success"
          );
          this.getFlowsList({
            ...this.generatePayload(),
            limit: this.take,
            offset: this.skip,
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              err.response?.data.Status.Message ||
              "Something went wrong, please try again later.",
            type: "error",
          });
        });
    },

    deleteCampaignHandler(payload) {
      this.isLoading = true;
      payload = {
        ...payload,
        clubId: this.clubId,
      };
      deleteFlowAutomationService(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification(
            "Your draft automation has been deleted.",
            "success"
          );
          this.getFlowsList({
            ...this.generatePayload(),
            limit: this.take,
            offset: this.skip,
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },

    statusChangeSuccessMessageGenerator(status) {
      if (status == 4) {
        return "Your live automation has been paused.";
      } else if (status == 2) {
        return "Your automation has been resumed. ";
      }
    },

    flowAutomationStatusChangeHandler(payload) {
      this.isLoading = true;
      let message = this.statusChangeSuccessMessageGenerator(
        payload?.currentStatus
      );
      payload = {
        ...payload,
        clubId: this.clubId,
      };
      updateFlowAutomationStatusService(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification(message, "success");
          this.getFlowsList({
            ...this.generatePayload(),
            limit: this.take,
            offset: this.skip,
          });
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },

    onCancelDeleteFlowModal() {
      this.deleteFlowModalVisibility = false;
    },

    onConfirmDeleteFlowModal() {
      this.deleteFlowModalVisibility = false;
      this.deleteCampaignHandler(this.payloadForDeleteFlowAutomation);
    },

    onCancelResumeFlow() {
      this.resumeFlowWarningModalVisibility = false;
    },

    onConfirmResumeFlow() {
      this.resumeFlowWarningModalVisibility = false;
      this.flowAutomationStatusChangeHandler(
        this.payloadForResumeFlowAutomation
      );
    },

    onCancelPauseFlow() {
      this.pauseFlowWarningModalVisibility = false;
    },

    onConfirmPauseFlow() {
      this.pauseFlowWarningModalVisibility = false;

      this.flowAutomationStatusChangeHandler(
        this.payloadForPauseFlowAutomation
      );
    },

    onCancelRescheduleCampaign() {
      this.RescheduleCampaignModalVisibility = false;
    },

    getDropId: function (e, item) {
      this.selectedFlowAutomation = item;

      if (e == 1) {
        const payload = {
          id: item.id,
          autoFlowStatus: 1,
          clubId: this.clubId,
        };
        this.cloneFlowAutomationHandler(payload);
      }

      if (e == 3) {
        this.payloadForDeleteFlowAutomation = {
          id: item.id,
        };
        this.deleteFlowModalVisibility = true;
      }

      if (e == 4) {
        this.payloadForPauseFlowAutomation = {
          id: item.id,
          currentStatus: 4,
          previousStatus: item.autoFlowStatus,
        };
        this.pauseFlowWarningModalVisibility = true;
      }

      if (e == 5) {
        this.payloadForResumeFlowAutomation = {
          id: item.id,
          currentStatus: 2,
          previousStatus: item.autoFlowStatus,
        };
        this.resumeFlowWarningModalVisibility = true;
      }

      if (e == 6) {
        this.doNavigateToEditFlow(item);
      }

      if (e == 7) {
        this.warningModals.archiveAutoflowModal.modalDetails.message = `Are you sure you want to archive "${item?.autoflowName}"?`;
        this.onOpenWarningModal("archiveAutoflowModal", {
          autoFlowId: item?.id,
          clubId: this.clubId,
          isArchived: true,
        });
      }

      if (e == 8) {
        this.warningModals.unarchiveAutoflowModal.modalDetails.message = `Are you sure you want to unarchive "${item?.autoflowName}"?`;
        this.onOpenWarningModal("unarchiveAutoflowModal", {
          autoFlowId: item.id,
          clubId: this.clubId,
          isArchived: false,
        });
      }
    },

    percentageCalc(a, b) {
      return Math.ceil((a / b) * 100);
    },
  },
};
</script>
<style scoped></style>
